export  default {
    props: {
        filterKey: {
            type: [String, Number],
            required: true
        },
    },
    methods: {
        saveFilterApplyingHistory (filter) {
            this.$store.commit(`catalogStore/${types.TOGGLE_SELECT_STATE_FOR_FILTER}`, {
                filterKey: this.filterKey,
                filterItem: filter
            })
            // this.$store.commit(`catalogStore/${filter.selected ? types.REMOVE_FILTER_HISTORY_ITEM : types.ADD_NEW_FILTER_TO_HISTORY}`, {
            //     id: filter.id,
            //     title: filter.title,
            // })
        }
    }
}
