//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterValueItemMixin from "./mixin/filterValueItemMixin";

export default {
    name: "GroupedCheckboxGrid",
    props: {
        filtersList: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    mixins: [filterValueItemMixin],
    computed: {
        groupedFiltersList() {
            let map = this.filtersList.reduce((r, i) => {
                r[i.extra.groupName] = r[i.extra.groupName] || [];
                r[i.extra.groupName].push(i);
                return r;
            }, {});
            let groups = [];
            for (let key in map) {
                groups.push({title: map[key][0].extra.groupName,list: map[key]});
            }
            return groups.sort((a, b) => {
                if (a.title > b.title) {
                    return 1;
                }
                if (a.title < b.title) {
                    return -1;
                }
                return 0;
            });
        }
    }
};
