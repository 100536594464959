//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterValueItemMixin from "./mixin/filterValueItemMixin";

export default {
    name: "CheckboxList",
    props: {
        filtersList: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    mixins: [filterValueItemMixin]
};
